import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import DrawControl from '../components/userManagement/DrawControl'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormLabel,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Stack,
  TextField,
  Typography,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  useTheme,
  Divider,
  Autocomplete,
} from '@mui/material'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  getGridSingleSelectOperators,
  GridFilterInputValueProps,
  GridValueGetterParams,
  GridColDef,
  GridSelectionModel,
} from '@mui/x-data-grid'
import api from 'balkerne-core/api'
import { useSnackbar } from 'notistack'
import { forwardRef, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useLocationGroups, useLocations, usePortfolioRisks, usePropertyAttributeCategories } from '../hooks/locations'
import { useRiskCategories } from '../hooks/analytics'
import Page from '../components/Page'
import { Risk } from 'balkerne-core/risks'
import { RiskLevelTag } from '../components/widgets/RiskLevelTag'
import { TransitionProps } from '@mui/material/transitions'
import { init } from '@sentry/browser'
import { LayerProps, FillLayer, Layer, MapRef, MapboxMap, Marker, Source } from 'react-map-gl'
import MapGL from 'react-map-gl'
import ConfigProvider from 'balkerne-core/config'
import * as turf from '@turf/turf'

const groupColumns: GridColDef[] = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'description', headerName: 'Description', width: 200 },
  { field: 'property_count', headerName: 'Property #', width: 100, type: 'number', align: 'right' },
]

const propertyColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'address',
    headerName: 'Address',
    width: 300,
    valueGetter: params => {
      return params.row?.address.street + ', ' + params.row?.address.town
    },
    hide: true,
    flex: 1,
  },
]

const customRiskFilterComponent = (props: GridFilterInputValueProps) => {
  const { item, applyValue } = props
  const [value, setValue] = useState('')
  const handleChange = (event, newValue) => {
    applyValue({ ...item, value: newValue.props.children })
    setValue(newValue.props.value)
  }
  return (
    <>
      <InputLabel>Value</InputLabel>
      <Select onChange={handleChange} value={value}>
        <MenuItem key={'riskFilterHigh'} value={Risk.HIGH}>
          High
        </MenuItem>
        <MenuItem key={'riskFilterMedium'} value={Risk.MEDIUM}>
          Medium
        </MenuItem>
        <MenuItem key={'riskFilterLow'} value={Risk.LOW}>
          Low
        </MenuItem>
        <MenuItem key={'riskFilterNegligible'} value={Risk.NEGLIGIBLE}>
          Negligible
        </MenuItem>
        <MenuItem key={'riskFilterNone'} value={Risk.NONE}>
          None
        </MenuItem>
      </Select>
    </>
  )
}

const PropertyGroups = () => {
  const { data: groups, refetch: refetchGroups, isLoading: isGroupsLoading } = useLocationGroups()
  const [groupId, setGroupId] = useState(groups?.[0]?.id ?? null)
  const {
    data: locations,
    refetch: refetchLocations,
    isLoading: isLocationsLoading,
  } = useLocations({ group_id: groupId }, (groups?.length ?? 0) > 0)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const onDelete = () => {
    setGroupId(null)
    refetchGroups()
  }

  const group = useMemo(() => {
    return groups?.find(x => x.id === groupId)
  }, [groups, groupId])

  return (
    <>
      <Page
        title="Property Groups"
        back
        PageActions={() => (
          <Button variant="outlined" onClick={() => setIsCreateModalOpen(true)}>
            Create Group
          </Button>
        )}>
        <Stack alignItems="center" direction="row" mb={2}>
          <Box>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography variant="h5">{group?.name ?? 'No selection'}</Typography>
              <Chip label={group?.property_count ?? 0} size="small" variant="outlined" />
            </Stack>
            <Typography variant="body1">
              {group?.description?.length > 0 ? group.description : 'No description'}
            </Typography>
          </Box>
          <Stack sx={{ flexGrow: 1 }} gap={1} direction="row" justifyContent="flex-end" flexBasis={1} flexGrow={1}>
            <Button variant="contained" disabled={groupId == null} onClick={() => setIsAssignModalOpen(true)}>
              Assign
            </Button>
            <Button variant="outlined" disabled={groupId == null} onClick={() => setIsDeleteModalOpen(true)}>
              Delete
            </Button>
          </Stack>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DataGrid
              sx={{ height: 500 }}
              columns={groupColumns}
              rows={groups}
              loading={isGroupsLoading}
              density="compact"
              autoPageSize
              onRowClick={params => setGroupId(params.id)}
            />
          </Grid>
          <Grid item xs={6}>
            <DataGrid
              sx={{ height: 500 }}
              columns={propertyColumns}
              rows={groupId !== null ? locations : []}
              loading={isLocationsLoading}
              density="compact"
              autoPageSize
            />
          </Grid>
        </Grid>
      </Page>
      <DeleteDialog
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDelete}
        groupId={groupId}
      />
      <CreateGroupDialog open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} onAdd={refetchGroups} />
      <AssignGroupDialog
        open={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        onSubmit={async () => {
          refetchLocations()
          refetchGroups()
        }}
        locations={locations}
        groupId={groupId}
      />
    </>
  )
}
const DialogTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />
})

const DeleteDialog = ({ open, onClose, onDelete, groupId, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleDelete = async () => {
    setIsSubmitting(true)
    if (groupId == null) return
    await api
      .delete(`/locations/groups/${groupId}`)
      .then(() => {
        enqueueSnackbar('Group deleted', { variant: 'success' })
        onDelete()
        onClose()
      })
      .catch(() => {
        enqueueSnackbar('Failed to delete group', { variant: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <Dialog open={open} onClose={onClose} keepMounted TransitionComponent={DialogTransition}>
      <DialogTitle>Delete Group</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to delete this group?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton onClick={handleDelete} variant="contained" color="error" loading={isSubmitting}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const CustomToolbar = ({ children }) => (
  <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
    {children}
    <GridToolbarFilterButton />
  </GridToolbarContainer>
)

const AssignGroupDialog = ({ open, onClose, onSubmit, locations, groupId, ...props }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { data: allLocations } = useLocations()
  const { data: risks } = usePortfolioRisks()
  const { data: riskCategories, isLoading: riskCategoriesLoading } = useRiskCategories()
  const { data: attributeCategories, isLoading: attributeCategoriesLoading } = usePropertyAttributeCategories()
  const [selectedLocations, setSelectedLocations] = useState(locations?.map(x => x.id) ?? [])
  const [availableSelection, setAvailableSelection] = useState<GridSelectionModel>([])
  const [assignedSelection, setAssignedSelection] = useState<GridSelectionModel>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filterModel, setFilterModel] = useState({
    availableLocations: {
      items: [],
    },
    assignedLocations: {
      items: [],
    },
  })
  const columns = useMemo(() => {
    const cols: any[] = [...propertyColumns]

    riskCategories.forEach(category => {
      cols.push({
        field: category.name,
        headerName: category.display_name,
        width: 100,
        valueGetter: params => {
          const risk = params.row?.risks.find(risk => risk.category_id === category.id)
          if (!risk) return ''
          switch (risk.value) {
            case Risk.HIGH:
              return 'High'
            case Risk.MEDIUM:
              return 'Medium'
            case Risk.LOW:
              return 'Low'
            case Risk.NEGLIGIBLE:
              return 'Negligible'
            case Risk.NONE:
              return 'None'
          }
        },
        renderCell: params => {
          const risk = params.row?.risks.find(risk => risk.category_id === category.id)
          if (risk?.value <= 0) return ''
          return <RiskLevelTag value={risk?.value} />
        },
        hide: true,
        filterOperators: getGridSingleSelectOperators()
          .filter(operator => operator.value !== 'isAnyOf')
          .map(operator => ({
            ...operator,
            InputComponent: operator.InputComponent ? customRiskFilterComponent : undefined,
          })),
      })
    })

    attributeCategories.forEach(category => {
      cols.push({
        field: category.name,
        headerName: category.name,
        valueGetter: params => {
          const attribute = params.row.attributes.find(attribute => attribute.category_id == category.id)
          return attribute ? attribute.value : ''
        },
        hide: true,
        flex: 1,
      })
    })
    return cols
  }, [riskCategories, attributeCategories])

  const columnVisibilityModel = useMemo(() => {
    const updatedColumnVisibilityModel = {
      availableLocations: {},
      assignedLocations: {},
    }
    const hideableColumns = columns.filter(col => col.hasOwnProperty('hide')).map(column => column.field)
    // Check if any hideable columns are being filtered
    Object.keys(filterModel).forEach(key => {
      const filteredHideableColumns = filterModel[key].items
        .filter(filterItem => hideableColumns.includes(filterItem.columnField))
        .map(filterItem => filterItem.columnField)

      hideableColumns.forEach(columnField => {
        if (filteredHideableColumns.includes(columnField)) {
          updatedColumnVisibilityModel[key][columnField] = true
        } else {
          updatedColumnVisibilityModel[key][columnField] = false
        }
      })
    })

    return updatedColumnVisibilityModel
  }, [columns, filterModel])

  const handleFilterModelChange = (key, newFilterModel) => {
    setFilterModel({ ...filterModel, [key]: newFilterModel })
  }

  useEffect(() => {
    setSelectedLocations(locations?.map(x => x.id) ?? [])
  }, [locations, allLocations])

  const availableLocations = useMemo(() => {
    const _locations: any[] = []
    for (const location of allLocations) {
      if (!selectedLocations.some(id => id === location.id)) {
        _locations.push({ ...location, risks: risks ? risks[location.id] : [] })
      }
    }
    return _locations
  }, [locations, allLocations, selectedLocations, risks])

  const assignedLocations = useMemo(() => {
    const _locations: any[] = []
    for (const location of allLocations) {
      if (selectedLocations.some(id => id === location.id)) {
        _locations.push({ ...location, risks: risks ? risks[location.id] : [] })
      }
    }
    return _locations
  }, [locations, allLocations, selectedLocations])

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const originalLocations = locations.map(x => x.id)
    const add = selectedLocations.filter(x => !originalLocations.includes(x))
    const remove = originalLocations.filter(x => !selectedLocations.includes(x))

    await api
      .post(`/locations/groups/${groupId}/reassign`, { add, remove })
      .then(() => {
        enqueueSnackbar('Group updated', { variant: 'success' })
        onSubmit()
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Failed to update group', { variant: 'error' })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const handleClose = () => {
    onClose()
    handleReset()
  }

  const onAdd = () => {
    setSelectedLocations([...selectedLocations, ...availableSelection])
  }

  const handleReset = () => {
    setSelectedLocations(locations?.map(x => x.id) ?? [])
  }

  const onRemove = () => {
    setSelectedLocations(selectedLocations.filter((x: any) => !assignedSelection.includes(x)))
  }

  const CustomToolbarAvailable = useMemo(
    () => () =>
      (
        <CustomToolbar>
          <Button onClick={onAdd} variant="outlined" size="small">
            Add
          </Button>
        </CustomToolbar>
      ),
    [onAdd],
  )

  const CustomToolbarAssigned = useMemo(
    () => () =>
      (
        <CustomToolbar>
          <Button onClick={onRemove} variant="outlined" size="small">
            Remove
          </Button>
        </CustomToolbar>
      ),
    [onRemove],
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      keepMounted
      TransitionComponent={DialogTransition}>
      <DialogTitle>Assign Properties</DialogTitle>
      <DialogContent>
        <Stack mb={2} sx={{ minHeight: 400 }} direction="row">
          <DataGrid
            sx={{ height: 500 }}
            columns={columns}
            rows={availableLocations}
            onSelectionModelChange={setAvailableSelection}
            density="compact"
            autoPageSize
            checkboxSelection
            components={{
              Toolbar: CustomToolbarAvailable,
            }}
            filterModel={filterModel['availableLocations']}
            onFilterModelChange={newFilterModel => handleFilterModelChange('availableLocations', newFilterModel)}
            columnVisibilityModel={columnVisibilityModel['availableLocations']}
          />
          <Box width={10}></Box>
          <DataGrid
            sx={{ height: 500 }}
            columns={columns}
            rows={assignedLocations}
            onSelectionModelChange={setAssignedSelection}
            density="compact"
            autoPageSize
            checkboxSelection
            components={{
              Toolbar: CustomToolbarAssigned,
            }}
            filterModel={filterModel['assignedLocations']}
            onFilterModelChange={newFilterModel => handleFilterModelChange('assignedLocations', newFilterModel)}
            columnVisibilityModel={columnVisibilityModel['assignedLocations']}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleReset}>
          Reset
        </Button>
        <LoadingButton variant="contained" onClick={handleSubmit} loading={isSubmitting}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const CreateGroupDialog = ({ open, onClose, onAdd, ...props }) => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()
  const schema = yup.object({
    type: yup.string().required('Type is required'),
    name: yup.string().min(1).required('Name is required'),
    description: yup.string(),
    propertyIds: yup.array().of(yup.number()),
  })
  const {
    register,
    handleSubmit: formSubmit,
    reset,
    formState: { isSubmitting },
    watch,
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { name: null, description: null, type: 'region', propertyIds: [] as number[] },
  })
  const { data: properties, isLoading: propertiesLoading } = useLocations()

  const handleClose = () => {
    reset()
    onClose()
  }

  const handleSubmit = async data => {
    await api
      .post('/locations/groups', {
        name: data.name,
        type: data.type,
        description: data.description,
        propertyIds: data.propertyIds,
      })
      .then(() => {
        enqueueSnackbar('Group created', { variant: 'success' })
        onAdd()
        handleClose()
      })
      .catch(err => {
        const defaultError = 'Error creating group'
        if (err.response.status == 400) {
          enqueueSnackbar(err.response?.data ?? defaultError, { variant: 'warning' })
        } else {
          enqueueSnackbar(defaultError, { variant: 'error' })
        }
      })
  }

  const groupType = watch('type')
  const selectedPropertyIds = watch('propertyIds')

  const selectedProperties = useMemo(() => {
    return properties.filter(property => selectedPropertyIds.includes(property.id))
  }, [properties, selectedPropertyIds])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      keepMounted
      TransitionComponent={DialogTransition}
      PaperProps={{ style: { backgroundColor: theme.palette.background.default } }}>
      <DialogTitle>Create Group</DialogTitle>
      <DialogContent>
        <Stack justifyContent="between" direction="row" sx={{ minHeight: 200 }}>
          <Box>
            {/* Type */}
            <Box mb={1}>
              <FormControl fullWidth>
                <FormLabel>Type</FormLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <ToggleButtonGroup
                      {...field}
                      exclusive
                      size="small"
                      onChange={(e, value) => {
                        field.onChange(value)
                      }}>
                      <ToggleButton value="region" key="region">
                        Regional
                      </ToggleButton>
                      <ToggleButton value="site" key="site">
                        Local Site
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </FormControl>
            </Box>

            {/* Name */}
            <Box mb={1}>
              <FormControl fullWidth>
                <FormLabel htmlFor="name">Name</FormLabel>
                <TextField id="name" {...register('name')} fullWidth />
              </FormControl>
            </Box>

            {/* Desc. */}
            <Box>
              <FormControl fullWidth>
                <FormLabel htmlFor="description">Description</FormLabel>
                <TextField id="description" {...register('description')} fullWidth />
                <Typography variant="caption" color="grey">
                  Optional
                </Typography>
              </FormControl>
            </Box>
          </Box>
          <Divider flexItem orientation="vertical" sx={{ mx: 2 }} />
          {/* Selection Component */}
          {groupType === 'site' ? (
            <LocalSiteSelection setSelectedPropertyIds={ids => setValue('propertyIds', ids)} />
          ) : (
            <MapSelection setSelectedPropertyIds={ids => setValue('propertyIds', ids)} />
          )}
          <Divider flexItem orientation="vertical" sx={{ mx: 2 }} />
          {/* Display Selected Properties */}
          <Box
            sx={{
              width: 400,
            }}>
            <DataGrid
              columns={propertyColumns}
              rows={selectedProperties}
              density="compact"
              autoPageSize
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    No selection
                  </Stack>
                ),
              }}
            />
          </Box>
        </Stack>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" onClick={formSubmit(handleSubmit)} loading={isSubmitting}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const localSitePropertyColumns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  {
    field: 'address',
    headerName: 'Address',
    width: 300,
    valueGetter: params => {
      return params.row?.address.street + ', ' + params.row?.address.town
    },
    hide: true,
    flex: 1,
  },
  {
    field: 'distance',
    headerName: 'Distance',
    width: 120,
    type: 'number',
    valueGetter: params => {
      return params.row?.metadata?.distance
    },
    renderCell: params => {
      return (
        <Typography variant="caption" sx={{ fontFamily: 'monospace' }}>{`${params.value.toFixed(0)} km`}</Typography>
      )
    },
  },
]

type SelectionProps = {
  setSelectedPropertyIds: (ids: number[]) => void
}

const LocalSiteSelection = ({ setSelectedPropertyIds }: SelectionProps) => {
  const { data: initLocations, isLoading: isInitLocationsLoading } = useLocations()
  const [initialLocation, setInitialLocation] = useState<any>(null)
  const { data: locations, isLoading: isLocationsLoading } = useLocations(
    { latitude: initialLocation?.coordinates.latitude, longitude: initialLocation?.coordinates.longitude },
    initialLocation !== null,
  )
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([])

  const sortedLocations = useMemo(() => {
    if (Array.isArray(locations)) {
      return [...locations].sort((a, b) => {
        return a?.metadata?.distance - b?.metadata?.distance
      })
    } else {
      return []
    }
  }, [locations])

  useEffect(() => {
    setSelectedPropertyIds(selectionModel as number[])
  }, [selectionModel])

  return (
    <Stack sx={{ mt: 0.5, width: 500, height: 500, gap: 1 }}>
      <Autocomplete
        options={initLocations}
        loading={isInitLocationsLoading}
        renderInput={params => <TextField {...params} label="Pin Location" />}
        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
        getOptionLabel={(option: any) => option.name}
        onChange={(e, value) => setInitialLocation(value)}
      />
      {initialLocation !== null ? (
        <DataGrid
          columns={localSitePropertyColumns}
          rows={sortedLocations}
          density="compact"
          autoPageSize
          loading={isLocationsLoading}
          checkboxSelection
          onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
        />
      ) : (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 500 }}>
          <Typography variant="subtitle1" color="textSecondary" align="center" gutterBottom>
            Select a location to view nearby properties
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

const MapSelection = ({ setSelectedPropertyIds }: SelectionProps) => {
  const INIT_LNG = -0.1442
  const INIT_LAT = 51.5072

  const ref = useRef<MapRef>(null)
  const { data: locations } = useLocations()
  const [geometry, setGeometry] = useState<GeoJSON.FeatureCollection<GeoJSON.Polygon> | null>(null)

  const featureCollection: GeoJSON.FeatureCollection<GeoJSON.Point> | null = useMemo(() => {
    if (!locations || locations.length === 0) return null
    const features = locations.map((location: any) => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [location.coordinates.longitude, location.coordinates.latitude],
      },
      properties: {
        id: location.id,
        name: location.name,
      },
    }))
    return {
      type: 'FeatureCollection',
      features,
    }
  }, [locations])

  const onUpdate = useCallback((e: any) => {
    setGeometry(e.features[0].geometry)
  }, [])

  const onDelete = useCallback((e: any) => {
    setGeometry(null)
  }, [])

  const points: GeoJSON.FeatureCollection<GeoJSON.Point> = useMemo(() => {
    if (featureCollection && geometry) {
      return turf.pointsWithinPolygon(featureCollection, geometry) as GeoJSON.FeatureCollection<GeoJSON.Point>
    }
    return { type: 'FeatureCollection', features: [] }
  }, [featureCollection, geometry])

  useEffect(() => {
    const selectedPropertyIds = points.features.map((feature: any) => feature.properties.id)
    setSelectedPropertyIds(selectedPropertyIds)
  }, [points])

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: 600, width: 600 }}>
      <MapGL
        ref={ref}
        reuseMaps
        initialViewState={{
          longitude: INIT_LNG,
          latitude: INIT_LAT,
          zoom: 8,
        }}
        style={{ width: '100%', height: '100%', zIndex: 0 }}
        mapStyle={'mapbox://styles/mapbox/dark-v9'}
        mapboxAccessToken={ConfigProvider.mapboxPublicKey}
        cursor="auto"
        interactiveLayerIds={['polygon-layer', 'circle-layer']}>
        <Source
          id="climate-analytics-hot-days"
          type="geojson"
          data={featureCollection ?? { type: 'FeatureCollection', features: [] }}>
          <Layer
            id="climate-analytics-hot-days"
            type="circle"
            paint={{
              'circle-radius': 1.5,
              'circle-stroke-width': 1,
              'circle-color': '#CCCCCC',
              'circle-stroke-color': '#CCCCCC',
            }}
          />
        </Source>
        <Source id="point-selection-source" type="geojson" data={points}>
          <Layer
            id="point-selection"
            source="point-selection-source"
            type="circle"
            paint={{
              'circle-radius': 3,
              'circle-color': '#00FFFF',
            }}
          />
        </Source>
        <DrawControl
          position="top-left"
          displayControlsDefault={false}
          controls={{
            polygon: true,
            trash: true,
          }}
          defaultMode={'draw_polygon'}
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </MapGL>
    </Stack>
  )
}

export default PropertyGroups
