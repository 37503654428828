import { AvatarPropsVariantOverrides, createTheme, Shadows, Theme, ThemeOptions, TypeText } from '@mui/material'
import { lighten, darken, alpha, outlinedInputClasses } from '@mui/material'

declare module '@mui/material/styles' {
  interface CustomTheme {}
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}

  interface CustomPalette {
    severity: {
      severe: string
      warning: string
      alert: string
      1: string
      2: string
      3: string
    }
    risk: {
      high: string
      medium: string
      low: string
      negligible: string
    }
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface Color {
    light?: string
  }

  interface TypeText {
    light?: string
    lighter?: string
    hint?: string
  }
  interface TypeBackground {
    borderColor?: string
    default: string
  }
  interface TypeAction {
    main?: string
  }
  interface Components {
    MuiDataGrid: any
  }
}
declare module '@mui/material/Avatar' {
  interface AvatarPropsVariantOverrides {
    'rounded-s': true
    'rounded-m': true
    'rounded-l': true
    'rounded-xl': true
    'rounded-xxl': true
  }
}

declare module '@mui/material/InputBase' {
  interface InputBasePropsSizeOverrides {
    tiny: true
  }
}

const primaryColor = 'rgb(106,109,167)'
// const primaryColor = '#556189'
// const primaryColor = '#3b425b'
// const primaryColor = '#4f5467'
const defaultBackgroundColor = '#fbfbfb'
let theme: Theme = createTheme({
  shape: {
    borderRadius: 8,
  },
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor, // 0652dd
      light: lighten(primaryColor, 0.2),
      dark: darken(primaryColor, 0.4),
      contrastText: '#f3f3f3',
    },
    secondary: {
      main: '#fad883',
      light: '#ffffb4',
      dark: '#c5a754',
      contrastText: '#000000',
    },
    text: {
      primary: '#121927',
      secondary: '#4F4F4F',
      light: '#FFFFFF',
      lighter: '#747a83',
      disabled: '#BDBDBD',
      hint: '#BDBDBD',
    },
    background: {
      // borderColor: '#ebebed',
      borderColor: alpha(primaryColor, 0.15),
      default: defaultBackgroundColor,
      paper: '#FFFFFF',
    },
    error: {
      main: '#cf7c7c',
      light: '#cf7c7c',
    },
    action: {
      main: '#5074b7',
    },
    severity: {
      severe: '#ff938e',
      warning: '#fecd8b',
      alert: '#f6f368',
      1: '#ff938e',
      2: '#fecd8b',
      3: '#f6f368',
    },
    risk: {
      high: '#dc1c33', // risk level: 4
      medium: '#ffa701', // risk level: 3
      low: '#378805', // risk level: 2
      negligible: '#646464', // risk level: 1
    },
  },
  typography: {
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    fontFamily: [
      'Figtree',
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  shadows: [
    'none',
    // '0px 2px 6px 0px rgba(0, 0, 0, 0.05)',
    // '0px 4px 6px rgba(0, 0, 0, 0.07)',
    // '0px 6px 6px rgba(0, 0, 0, 0.08)',
    // '0px 8px 7px rgba(0, 0, 0, 0.09)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
})

theme = createTheme(theme, {
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 'unset',
        },
      },
      variants: [
        {
          props: { variant: 'subtitle1' },
          style: {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
        {
          props: { variant: 'subtitle2' },
          style: {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.background.borderColor,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: 4,
          borderColor: theme.palette.primary.main,
          outlineColor: theme.palette.primary.main,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body': {
          backgroundColor: theme.palette.background.default,
          margin: 0,
          fontSize: '12pt',
          lineHeight: 1.2,
          minHeight: '100%',
          height: '100%',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        // shrink: true,
      },
      styleOverrides: {},
    },
    // Outlined Input
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
        },
      },
      variants: [
        {
          props: { size: 'tiny' },
          style: {
            '& .MuiSelect-select': {
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '8px',
              paddingRight: '8px',
              fontSize: '12px',
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            backgroundColor: '#f3f3f3',
            borderRadius: 8,
          },
          backgroundColor: theme.palette.background.paper,
          position: 'relative',
          borderRadius: 8,
          fontSize: 16,
          width: 'auto',
          transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
          [`& .${outlinedInputClasses['notchedOutline']}`]: {
            borderColor: theme.palette.background.borderColor,
          },
          [`&:hover .${outlinedInputClasses['notchedOutline']}`]: {
            borderColor: alpha(theme.palette.primary.main, 0.5),
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    // App Bar
    MuiAppBar: {
      defaultProps: {
        color: 'default',
      },
    },
    // Data Grid
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.background.borderColor,
          backgroundColor: theme.palette.background.paper,
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
        },
      },
    },

    // Avatar
    MuiAvatar: {
      variants: [
        {
          props: { variant: 'rounded-s' },
          style: {
            height: '30px',
            width: '30px',
            borderRadius: '4px',
          },
        },
        {
          props: { variant: 'rounded-m' },
          style: {
            height: '42px',
            width: '42px',
            borderRadius: '4px',
          },
        },
        {
          props: { variant: 'rounded-l' },
          style: {
            height: '60px',
            width: '60px',
            borderRadius: '4px',
          },
        },
        {
          props: { variant: 'rounded-xl' },
          style: {
            height: '100px',
            width: '100px',
            borderRadius: '4px',
          },
        },
        {
          props: { variant: 'rounded-xxl' },
          style: {
            height: '140px',
            width: '140px',
            borderRadius: '4px',
          },
        },
      ],
    },
    // Button
    MuiButton: {
      styleOverrides: {
        root: {
          // borderRadius: '8px',
          fontWeight: '600',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    // Link
    MuiLink: {},
    // Card
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '18px 18px',
          border: `1px solid ${theme.palette.background.borderColor}`,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '0',
          paddingBottom: '6px',
          '& .MuiCardHeader-title': {
            fontSize: '16px',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          // fontSize: 16, // A little too big
          borderRadius: 6,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled.Mui-selected': {
            backgroundColor: 'rgba(18, 25, 39, 0.04)',
            // Icon color
            color: theme.palette.text.disabled,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: theme.palette.background.borderColor,
        },
      },
    },
    MuiRadioGroup: {
      styleOverrides: {
        root: {},
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '&.MuiSwitch-sizeMedium': {
            padding: 8,
            '& .MuiSwitch-track': {
              borderRadius: 22 / 2,
              backgroundColor: theme.palette.background.borderColor,
              '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
              },
              // '&:before': {
              //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              //     theme.palette.getContrastText(theme.palette.primary.main),
              //   )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
              //   left: 12,
              // },
              // '&:after': {
              //   backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
              //     theme.palette.getContrastText(theme.palette.primary.main),
              //   )}" d="M19,13H5V11H19V13Z" /></svg>')`,
              //   right: 12,
              // },
            },
            '& .MuiSwitch-thumb': {
              boxShadow: 'none',
              width: 16,
              height: 16,
              margin: 2,
            },
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '4px 10px',
          borderRadius: 8,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: '4px 10px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '24px',
          marginRight: '14px',
        },
      },
    },
  },
} as ThemeOptions)

// theme.palette.severity[1] = theme.palette.severity.severe
// theme.palette.severity[2] = theme.palette.severity.warning
// theme.palette.severity[3] = theme.palette.severity.alert

export default theme
