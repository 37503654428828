import {
  Avatar,
  Box,
  Button,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useMemo, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Page from '../components/Page'
import { useHistory } from 'react-router-dom'
import siteMap from '../siteMap'
import { PermissionGate, ViewPermissions } from 'balkerne-core/roles'
import { useAlertTypes, useAlertingSubscriptions, useNotificationMutes, usePortfolioAlerts } from '../hooks/alerts'
import CardDataGrid from '../components/CardDataGrid'
import { GridColDef, getGridSingleSelectOperators } from '@mui/x-data-grid'
import { ago } from 'balkerne-core/time'
import SeverityIcon from '../components/SeverityIcon'
import { useLocation } from 'react-router-dom'
import AlertTypeIcon from '../components/AlertTypeIcon'
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined'
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined'
import api from 'balkerne-core/api'
import { useMutation, useQueryClient } from 'react-query'
import FmdBadOutlinedIcon from '@mui/icons-material/FmdBadOutlined'
import { useLocations } from '../hooks/locations'
import { useSelector } from 'react-redux'
import { RootState } from '../store'

const columns: GridColDef[] = [
  {
    field: 'property_name',
    headerName: 'Property',
    width: 120,
    valueGetter: ({ row }) => row.property?.name,
  },
  {
    field: 'severity',
    headerName: 'Severity',
    sortable: false,
    valueOptions: [
      { value: 1, label: 'Severe' },
      { value: 2, label: 'Warning' },
      { value: 3, label: 'Alert' },
    ],
    filterOperators: getGridSingleSelectOperators().filter(op => op.value === 'is'),
    renderCell: params => <SeverityIcon height={25} severity={params.row.severity} />,
  },
  {
    field: 'type_display',
    headerName: 'Type',
    width: 120,
  },

  {
    field: 'updated_at',
    headerName: 'Updated',
    width: 100,
    valueFormatter: ({ value }) => ago(value),
  },
  {
    field: 'created_at',
    headerName: 'Created',
    width: 100,
    valueFormatter: ({ value }) => ago(value),
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 180,
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 1,
  },
]

export const PortfolioAlerts = () => {
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations } = useLocations({ group_id: groupId })
  const locationIds = useMemo(() => locations?.map(location => location.id) ?? [], [locations])
  const [muteDialogOpen, setMuteDialogOpen] = useState(false)
  const location = useLocation()
  const history = useHistory()
  const theme = useTheme()
  const monthAgo = useMemo(() => {
    const date = new Date()
    date.setMonth(date.getMonth() - 1)
    return date
  }, [])
  const { data: unsortedUnfilteredAlerts, isLoading } = usePortfolioAlerts({
    last_updated: monthAgo,
    only_active: true,
  })
  const unsortedAlerts = useMemo(() => {
    if (unsortedUnfilteredAlerts === undefined || unsortedUnfilteredAlerts === null) return []
    return unsortedUnfilteredAlerts.filter(alert => locationIds.includes(alert.property.id))
  }, [unsortedUnfilteredAlerts, locationIds])

  // Sort by severity, then by updated last date
  const alerts = useMemo(() => {
    if (unsortedAlerts === undefined || unsortedAlerts === null) return []
    const alerts = [...unsortedAlerts]
    alerts.sort((a, b) => {
      if (a.severity > b.severity) return 1
      if (a.severity < b.severity) return -1

      if (a.updated_at > b.updated_at) return -1
      if (a.updated_at < b.updated_at) return 1
      return 0
    })
    return alerts
  }, [unsortedAlerts])

  const isCompact = useMediaQuery(theme.breakpoints.down('xl'))
  return (
    <>
      <Page
        title="Alerts"
        size="lg"
        back={location.state?.back}
        PageActions={() => (
          <Stack direction="row" gap={1}>
            <PermissionGate action={ViewPermissions.AlertManagement}>
              <Button variant="outlined" color="primary" onClick={() => setMuteDialogOpen(true)}>
                Mute Notifications
              </Button>
              <Button variant="outlined" color="primary" onClick={() => history.push(siteMap.AlertConfigurations.path)}>
                Manage Configurations
              </Button>
              <Button variant="outlined" color="primary" onClick={() => history.push(siteMap.AlertSubscriptions.path)}>
                Manage Properties
              </Button>
            </PermissionGate>
          </Stack>
        )}>
        <Box sx={{ height: { xs: 500, xl: 800 } }}>
          <CardDataGrid
            getRowId={row => row.event_id}
            rows={alerts}
            columns={columns}
            onCellDoubleClick={({ row }) => history.push(siteMap.Property.getPath(row.property.id))}
            density={isCompact ? 'compact' : 'standard'}
            loading={isLoading}
            components={{
              NoRowsOverlay: () => (
                <Stack justifyContent="center" alignItems="center" height="100%" gap={2}>
                  <img
                    src={require('../images/no-results.png')}
                    alt="No active alerts"
                    style={{ width: 50, height: 50, opacity: 0.2 }}
                  />
                  <Typography variant="body2">No active alerts</Typography>
                </Stack>
              ),
            }}
          />
        </Box>
      </Page>
      <MuteNotificationDialog open={muteDialogOpen} onClose={() => setMuteDialogOpen(false)} />
    </>
  )
}

type MuteMutationProps = {
  alert_type_id: number
  subscription_id: number | null
}

const MuteNotificationDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { data: subscribers, isLoading: isSubscriptionsLoading } = useAlertingSubscriptions()
  const { data: alertTypes, isLoading: isAlertTypesLoading } = useAlertTypes()
  const { data: allMutes, isLoading: mutesIsLoading, path: mutesPath } = useNotificationMutes()
  const [selectedLocation, setSelectedLocation] = useState<any | null>(null)
  const [isSelectingAll, setIsSelectingAll] = useState(false)
  const queryClient = useQueryClient()

  const muteMutation = useMutation({
    mutationFn: (data: MuteMutationProps) => {
      return api.post('/alerts/mute', data)
    },
    onMutate: async (newData: MuteMutationProps) => {
      await queryClient.cancelQueries(mutesPath)
      const previousData = queryClient.getQueryData(mutesPath)
      queryClient.setQueryData(mutesPath, (old: any) => {
        const subscriptionId = newData.subscription_id ?? 'null' // null is used to specify all properties
        const alertTypeId = newData.alert_type_id
        const newMutes = { ...old }
        newMutes[subscriptionId] = { ...newMutes[subscriptionId], [alertTypeId]: true }
        return newMutes
      })
      return previousData
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(mutesPath, context)
    },
    onSettled: (data, variables, context) => {
      queryClient.invalidateQueries(mutesPath)
    },
  })

  const unmuteMutation = useMutation({
    mutationFn: (data: MuteMutationProps) => {
      return api.post('/alerts/unmute', data)
    },
    onMutate: async (newData: MuteMutationProps) => {
      await queryClient.cancelQueries(mutesPath)
      const previousData = queryClient.getQueryData(mutesPath)
      queryClient.setQueryData(mutesPath, (old: any) => {
        const subscriptionId = newData.subscription_id ?? 'null' // null is used to specify all properties
        const alertTypeId = newData.alert_type_id
        const newMutes = { ...old }
        if (subscriptionId in newMutes) {
          delete newMutes[subscriptionId][alertTypeId]
        }
        return newMutes
      })
      return previousData
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(mutesPath, context)
    },
    onSettled: (data, variables, context) => {
      queryClient.invalidateQueries(mutesPath)
    },
  })

  const selectedMutes = useMemo(() => {
    if (isSelectingAll) return allMutes['null'] ?? {}
    if (allMutes === undefined || allMutes === null) return {}
    return allMutes[selectedLocation?.id ?? 'null'] ?? {}
  }, [allMutes, selectedLocation, isSelectingAll, muteMutation.status, unmuteMutation.status])

  const isLoading = mutesIsLoading || isSubscriptionsLoading || isAlertTypesLoading
  const noSelection = selectedLocation === null && !isSelectingAll

  const getMuteCount = (subscriptionId: number) => {
    const globalMutes = allMutes['null'] ?? {}
    const subscriberMutes = allMutes[subscriptionId] ?? {}
    return new Set([...Object.keys(globalMutes), ...Object.keys(subscriberMutes)]).size
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          Notifications
          <Button onClick={onClose} variant="text">
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{
            width: 500,
            pt: 1,
          }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Mute notifications for individual or all properties
          </Typography>
          {/* Controls */}
          <Stack direction="row" gap={2}>
            <Tooltip
              arrow
              title={
                <>
                  <Typography variant="body2">
                    Configure global settings. <br />
                    <br />
                    It will override individual settings without removing them.
                  </Typography>
                </>
              }
              describeChild>
              <FormGroup>
                {/* All Properties */}
                <FormControlLabel
                  sx={{ whiteSpace: 'nowrap' }}
                  labelPlacement="end"
                  control={<Switch />}
                  label="All"
                  value={isSelectingAll}
                  onChange={e => setIsSelectingAll((e.target as HTMLInputElement).checked)}
                />
              </FormGroup>
            </Tooltip>

            {/* Select Property */}
            <Autocomplete
              id="property-id"
              size="small"
              sx={{ width: '100%', maxWidth: 800 }}
              autoComplete
              filterOptions={createFilterOptions({ limit: 50 })}
              onChange={(event, newValue) => setSelectedLocation(newValue)}
              autoHighlight
              disabled={isSelectingAll}
              autoFocus={false}
              autoSelect={false}
              options={subscribers}
              value={selectedLocation}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props: any, option) => (
                <Box {...props} key={option.id}>
                  <Stack direction="row" justifyContent="space-between" spacing={1} flexGrow={1}>
                    <Typography variant="body1">{option.property.name}</Typography>
                    {getMuteCount(option.id) > 0 && (
                      <Typography variant="body2" color="primary">
                        {getMuteCount(option.id)} muted
                      </Typography>
                    )}
                  </Stack>
                </Box>
              )}
              getOptionLabel={option => option.property.name}
              renderInput={params => <TextField {...params} label="Property" placeholder="Name" />}
              loading={isSubscriptionsLoading}
            />
          </Stack>

          {/* List of Alert Types w/ Actions */}
          <List dense sx={{ maxHeight: 500, overflowY: 'auto', my: 2 }}>
            {alertTypes?.map(type => (
              <ListItem
                key={type.id}
                secondaryAction={
                  <Stack direction="row" alignItems="center">
                    {allMutes['null']?.[type.id] && !isSelectingAll && !noSelection && (
                      <Tooltip title="Overriden in global settings" sx={{ mr: 1 }}>
                        <FmdBadOutlinedIcon />
                      </Tooltip>
                    )}
                    <ToggleButtonGroup
                      size="small"
                      value={noSelection ? null : selectedMutes[type.id] ? 'off' : 'on'}
                      exclusive
                      disabled={noSelection || (allMutes['null']?.[type.id] && !isSelectingAll)}
                      onChange={(e, value) => {
                        const subscriptionId = isSelectingAll ? null : selectedLocation.id
                        if (value === 'on' && selectedMutes?.[type.id]) {
                          unmuteMutation.mutate({ alert_type_id: type.id, subscription_id: subscriptionId })
                        } else if (value === 'off' && !selectedMutes?.[type.id]) {
                          muteMutation.mutate({ alert_type_id: type.id, subscription_id: subscriptionId })
                        }
                      }}>
                      <ToggleButton value="on">
                        <Tooltip title="Unmute">
                          <NotificationsActiveOutlinedIcon />
                        </Tooltip>
                      </ToggleButton>
                      <ToggleButton value="off" title="Mute">
                        <Tooltip title="Mute">
                          <NotificationsOffOutlinedIcon />
                        </Tooltip>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                }>
                <ListItemAvatar>
                  <Avatar variant="rounded-m" sx={{ backgroundColor: 'rgb(250,251,251)' }}>
                    <AlertTypeIcon type={type.name} height={36} width={36} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={type.display_name} secondary={`Units: ${type.units ?? 'N/A'}`} />
              </ListItem>
            ))}
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default PortfolioAlerts
