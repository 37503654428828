import { useState, useMemo, useRef, useEffect, ReactNode, useCallback } from 'react'
import Stack from '@mui/material/Stack'
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Map, { Marker, Popup, NavigationControl, ScaleControl, MapRef, Layer, Source, LayerProps } from 'react-map-gl'
import Typography from '@mui/material/Typography'
import { propertyRiskIcons, floodIcons } from './maps/resources'
import { useLocation, useLocations, useLocationsWithRisks } from '../hooks/locations'
import { usePortfolioAlerts } from '../hooks/alerts'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  ToggleButton,
  alpha,
  darken,
  useTheme,
} from '@mui/material'
import { SurfaceWaterType, useHistoricalFlood, useProtectedNature, useRiverSea, useSurfaceWater } from '../hooks/mapbox'
import ConfigProvider from 'balkerne-core/config'
import { MapLegendWidget } from './widgets/MapLegendWidget'
import mapboxgl, { GeoJSONSource } from 'mapbox-gl'
import { color as layerColor } from '../hooks/mapbox'
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined'
import KayakingOutlinedIcon from '@mui/icons-material/KayakingOutlined'
import AlertTypeIcon from './AlertTypeIcon'
import SeverityIcon from './SeverityIcon'
import { DisplaySeverity } from 'balkerne-core/alerts'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { ago } from 'balkerne-core/time'
import { FeatureGate } from 'balkerne-core/roles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'

export const PortfolioMap = ({ handleRedirect }) => {
  const mapRef = useRef<MapRef | null>(null)
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const locationIds = useMemo(() => locations?.map(location => location.id) ?? [], [locations])

  console.log('locationIds', locationIds)

  const { data: allAlerts, isLoading: isAlertsLoading } = usePortfolioAlerts({ only_active: true })
  const alerts = useMemo(
    () => allAlerts?.filter(alert => locationIds.includes(alert.property.id)),
    [allAlerts, locationIds],
  )

  console.log('alerts', alerts)

  const [isSatellite, setSatellite] = useState(false)
  const [riskAnchorEl, setRiskAnchorEl] = useState<null | HTMLElement>(null)
  const [riskType, setRiskType] = useState<'river_sea' | 'surface_water'>('river_sea')
  const [isLoading, setLoading] = useState(false)

  const [entity, setEntity] = useState<'assets' | 'threats' | 'risk'>('assets')
  const [layer, setLayer] = useState<
    null | 'historic-flood' | 'river-sea-flood' | 'surface-water-flood' | 'protected-nature'
  >(null)

  const [popupContent, setPopupContent] = useState<ReactNode>(null)
  const [coords, setPopupCoordinates] = useState<{ lat: number; lng: number } | null>(null)
  const [selectedPropertyId, setSelectedPropertyId] = useState<number | null>(null)
  const [previousCameraState, setPreviousCameraState] = useState<{
    center: [number, number]
    zoom: number
    bearing: number
    pitch: number
  } | null>(null)
  const { data: location } = useLocation(selectedPropertyId, { geojson: true })

  const { Source: WaterSurfaceSource, setType, type: surfaceWaterType } = useSurfaceWater('extent')
  const [surfaceAnchorEl, setSurfaceAnchorEl] = useState<null | HTMLElement>(null)
  const isSurfaceOpen = Boolean(surfaceAnchorEl)

  const { Source: RiverSeaSource } = useRiverSea()
  const { Source: HistoricalFloodSource } = useHistoricalFlood()
  const { Source: ProtectedNatureSource } = useProtectedNature()
  const theme = useTheme()

  const handleSelectProperty = useCallback(
    (propertyId: number, coords: { lat: number; lng: number }, children: ReactNode): void => {
      const map = mapRef.current?.getMap()
      if (!map) return

      map.flyTo({
        center: [coords.lng, coords.lat],
        zoom: 17,
        bearing: 30,
        pitch: 45,
        offset: [0, 100],
      })

      setPreviousCameraState({
        center: [map.getCenter().lng, map.getCenter().lat] as [number, number],
        zoom: map.getZoom(),
        bearing: map.getBearing(),
        pitch: map.getPitch(),
      })
      setSelectedPropertyId(propertyId)
      setPopupCoordinates(coords)
      setPopupContent(children)
    },
    [mapRef],
  )

  const handleClosePopup = useCallback(() => {
    setSelectedPropertyId(null)
    setPopupCoordinates(null)
    setPopupContent(null)
    const map = mapRef.current?.getMap()
    if (map !== undefined && previousCameraState !== null) {
      map.flyTo({ ...previousCameraState, speed: 3 })
    }
  }, [mapRef, previousCameraState])

  const handleViewProperty = useCallback(() => {
    if (selectedPropertyId) {
      handleRedirect(selectedPropertyId)
    }
  }, [selectedPropertyId])

  const handleClick = (e: mapboxgl.MapLayerMouseEvent) => {
    const map = mapRef.current?.getMap()
    if (e.features === undefined || e.features.length === 0 || map === undefined) return

    const feature = e.features[0]
    if (feature.source === 'portfolio-assets') {
      const mapboxSource = map.getSource('portfolio-assets') as GeoJSONSource

      // Clustered Layer
      if (feature.layer.id === 'clusters') {
        const clusterId = feature?.properties?.cluster_id
        if (clusterId === undefined) return
        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return
          map.flyTo({
            // @ts-ignore
            center: feature.geometry.coordinates,
            zoom: zoom,
            speed: 3,
          })
        })
      } else if (feature.layer.id === 'unclustered-point') {
        const props = feature.properties
        console.log(props)
        if (props && props.id && props.latitude && props.longitude && props.name) {
          handleSelectProperty(
            props.id,
            {
              lat: props.latitude,
              lng: props.longitude,
            },
            <Stack minWidth={300}>
              <Typography variant="h6" fontWeight="bold">
                {props.name}
              </Typography>
              <Typography variant="subtitle2">
                {props.street}, {props.town}, {props.postcode}, {props.country}
              </Typography>
            </Stack>,
          )
        }
      }
    } else if (feature.source === 'risk-source') {
      const props = feature.properties
      if (props && props.id && props.name) {
        handleSelectProperty(
          props.id,
          {
            lat: props.latitude,
            lng: props.longitude,
          },
          <Stack minWidth={300}>
            <Typography variant="h6" fontWeight="bold">
              {props.name}
            </Typography>
          </Stack>,
        )
      }
    }
  }

  useEffect(() => {
    // Set to threats if any are active
    if (alerts?.length > 0) {
      setEntity('threats')
    } else {
      setEntity('assets')
    }
  }, [alerts, isAlertsLoading])

  return (
    <Card sx={{ position: 'relative', p: 0, m: 0 }}>
      {/* Controls */}
      <Box
        bgcolor={alpha(theme.palette.background.paper, 0.8)}
        sx={{
          position: 'absolute',
          borderBottomRightRadius: 'inherit',
          top: 0,
          left: 0,
          minWidth: 150,
          zIndex: 2,
          p: 2,
        }}>
        {/* Markers */}
        <FormControl>
          <FormLabel id="entity-radio-group-label">
            <Typography variant="subtitle2">Markers</Typography>
          </FormLabel>

          <RadioGroup
            aria-labelledby="entity-radio-group-label"
            value={entity}
            defaultValue={entity}
            name="entity-radio-group"
            onChange={e => setEntity(e.target.value as any)}>
            <FormControlLabel value="assets" label="Assets" control={<Radio sx={{ userSelect: 'none' }} />} />
            <FeatureGate feature="alerts">
              <FormControlLabel
                value="threats"
                label="Active Threats"
                control={<Radio sx={{ userSelect: 'none' }} />}
              />
            </FeatureGate>
          </RadioGroup>
        </FormControl>
        <Divider sx={{ my: 1 }} />

        {/* Layers */}
        <FormGroup>
          <FormLabel id="layer-radio-group-label">
            <Typography variant="subtitle2">Layers</Typography>
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={layer === 'historic-flood'}
                onChange={e => setLayer(layer == 'historic-flood' ? null : 'historic-flood')}
              />
            }
            label={<Typography variant="body1">Historical Floods</Typography>}
            sx={{ userSelect: 'none' }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={layer === 'river-sea-flood'}
                onChange={e => setLayer(layer == 'river-sea-flood' ? null : 'river-sea-flood')}
              />
            }
            label={<Typography variant="body1">River &#038; Sea</Typography>}
            sx={{ userSelect: 'none' }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={layer === 'surface-water-flood'}
                onChange={e => setLayer(layer == 'surface-water-flood' ? null : 'surface-water-flood')}
              />
            }
            label={
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Typography variant="body1">Surface Water</Typography>
                <IconButton
                  size="small"
                  onClick={e => setSurfaceAnchorEl(e.currentTarget)}
                  aria-controls={isSurfaceOpen ? 'surface-water-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={isSurfaceOpen ? 'true' : undefined}>
                  <LayersOutlinedIcon />
                </IconButton>
                <Menu
                  id="surface-water-menu"
                  anchorEl={surfaceAnchorEl}
                  open={isSurfaceOpen}
                  onClose={() => setSurfaceAnchorEl(null)}
                  onClick={() => setSurfaceAnchorEl(null)}>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.EXTENT}
                    onClick={() => setType(SurfaceWaterType.EXTENT)}>
                    Extent
                  </MenuItem>
                  <ListSubheader>Depth Probability</ListSubheader>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.DEPTH_HIGH}
                    onClick={() => setType(SurfaceWaterType.DEPTH_HIGH)}>
                    Depth | High
                  </MenuItem>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.DEPTH_MEDIUM}
                    onClick={() => setType(SurfaceWaterType.DEPTH_MEDIUM)}>
                    Depth | Medium
                  </MenuItem>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.DEPTH_LOW}
                    onClick={() => setType(SurfaceWaterType.DEPTH_LOW)}>
                    Depth | Low
                  </MenuItem>
                  <ListSubheader>Velocity Probability</ListSubheader>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.VELOCITY_HIGH}
                    onClick={() => setType(SurfaceWaterType.VELOCITY_HIGH)}>
                    Velocity | High
                  </MenuItem>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.VELOCITY_MEDIUM}
                    onClick={() => setType(SurfaceWaterType.VELOCITY_MEDIUM)}>
                    Velocity | Medium
                  </MenuItem>
                  <MenuItem
                    selected={surfaceWaterType === SurfaceWaterType.VELOCITY_LOW}
                    onClick={() => setType(SurfaceWaterType.VELOCITY_LOW)}>
                    Velocity | Low
                  </MenuItem>
                </Menu>
              </Stack>
            }
            sx={{ userSelect: 'none' }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={layer === 'protected-nature'}
                onChange={e => setLayer(layer == 'protected-nature' ? null : 'protected-nature')}
              />
            }
            label={<Typography variant="body1">Protected Nature</Typography>}
            sx={{ userSelect: 'none' }}
          />
        </FormGroup>
        <Divider sx={{ my: 1 }} />
        {/* Switch between our custom style and satellite style */}
        <FormGroup>
          <FormLabel id="entity-radio-group-label">
            <Typography variant="subtitle2">View</Typography>
          </FormLabel>
          <FormControlLabel
            control={<Switch checked={isSatellite} onChange={() => setSatellite(!isSatellite)} />}
            label="Satellite"
            sx={{ userSelect: 'none' }}
          />
        </FormGroup>
      </Box>
      <Box sx={{ height: { xs: 600, xl: 900 } }}>
        <Map
          ref={mapRef}
          reuseMaps
          onClick={handleClick}
          interactiveLayerIds={['clusters', 'unclustered-point', 'risk-layer']}
          initialViewState={{
            longitude: -2.3,
            latitude: 51.8,
            zoom: 6,
            pitch: 0,
            bearing: 0,
          }}
          style={{ width: '100%', height: '100%', borderRadius: '8px' }}
          mapStyle={
            isSatellite ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/balkerne/clmqen49o00bm01ns7ur41hzj'
          }
          mapboxAccessToken={ConfigProvider.mapboxPublicKey}>
          {/* Loader */}
          {isLoading && (
            <Stack
              justifyContent="center"
              alignItems="center"
              width="100%"
              height="100%"
              top={0}
              left={0}
              zIndex={1}
              position={'absolute'}
              bgcolor={alpha(theme.palette.background.paper, 0.5)}>
              <CircularProgress />
            </Stack>
          )}

          {/* Selected Asset */}
          {location?.geometry && (
            <Source id="select-property" type="geojson" data={location.geometry}>
              <Layer
                id="select-property-layer"
                type="fill-extrusion"
                paint={{
                  'fill-extrusion-color': 'red',
                  'fill-extrusion-height': 6,
                  'fill-extrusion-base': 0,
                  'fill-extrusion-opacity': 0.6,
                }}
              />
            </Source>
          )}

          {/* Popup */}
          {popupContent !== null && coords && (
            <Popup
              maxWidth="none"
              closeButton={true}
              anchor="bottom-left"
              closeOnClick={false}
              onClose={handleClosePopup}
              latitude={Number(coords.lat)}
              longitude={Number(coords.lng)}>
              {popupContent}
              <Divider sx={{ mb: 1 }} />
              <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" size="small" onClick={handleClosePopup}>
                  Close
                </Button>
                <Button variant="contained" size="small" onClick={handleViewProperty}>
                  More <NavigateNextRoundedIcon />
                </Button>
              </Stack>
            </Popup>
          )}

          {/* Markers */}
          <Box>
            {mapRef.current?.getMap() != undefined && (
              <>
                {/* Alerts */}
                {entity === 'threats' && (
                  <ActiveThreatLayer
                    onClick={handleSelectProperty}
                    setLoading={setLoading}
                    map={mapRef.current.getMap()}
                  />
                )}
                {/* Properties */}
                {entity === 'assets' && (
                  <AssetLayer onClick={handleSelectProperty} setLoading={setLoading} map={mapRef.current.getMap()} />
                )}
                {/* Risks */}
                {entity === 'risk' && (
                  <RiskLayer setLoading={setLoading} map={mapRef.current.getMap()} type={riskType} />
                )}
              </>
            )}
          </Box>

          {/* Layers */}
          {layer === 'surface-water-flood' && <WaterSurfaceSource minZoom={9}></WaterSurfaceSource>}
          {layer === 'river-sea-flood' && <RiverSeaSource minZoom={9}></RiverSeaSource>}
          {layer === 'historic-flood' && <HistoricalFloodSource minZoom={9}></HistoricalFloodSource>}
          {layer === 'protected-nature' && <ProtectedNatureSource minZoom={12}></ProtectedNatureSource>}
          <ScaleControl />
          <NavigationControl />
        </Map>
        <MapLegendWidget
          showSurfaceWater={layer === 'surface-water-flood'}
          showRiverSea={layer === 'river-sea-flood'}
          showFloods={layer === 'historic-flood'}
          surfaceWaterType={surfaceWaterType}
          overMap
        />
      </Box>
    </Card>
  )
}

type CustomLayerProps = {
  map: mapboxgl.Map
  setLoading: (loading: boolean) => void
  onClick?: (propertyId: number, coords: { lat: number; lng: number }, children?: ReactNode) => void
}

const AssetLayer = ({ map, setLoading, onClick }: CustomLayerProps) => {
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations, isLoading } = useLocations({ group_id: groupId })
  if (!locations) return null

  useEffect(() => {
    setLoading(isLoading)
    return () => {
      setLoading(false)
    }
  }, [isLoading])

  const featureCollection = useMemo(() => {
    const features = locations.map(location => ({
      type: 'Feature',
      properties: {
        id: location.id,
        name: location.name,
        latitude: location.coordinates.latitude,
        longitude: location.coordinates.longitude,
        country: location.address.country,
        town: location.address.town,
        postcode: location.address.postcode,
        street: location.address.street,
      },
      geometry: {
        type: 'Point',
        coordinates: [location.coordinates.longitude, location.coordinates.latitude],
      },
    }))
    return {
      type: 'FeatureCollection',
      features: features,
    }
  }, [locations])

  const theme = useTheme()

  const clusterLayer: LayerProps = {
    id: 'clusters',
    type: 'circle',
    source: 'assets-source',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': [
        'step',
        ['get', 'point_count'],
        darken(theme.palette.primary.main, 0.05),
        20,
        darken(theme.palette.primary.main, 0.35),
        50,
        darken(theme.palette.primary.main, 0.7),
      ],
      'circle-radius': ['step', ['get', 'point_count'], 15, 20, 20, 50, 25],
    },
  }

  const clusterCountLayer: LayerProps = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'assets-source',
    filter: ['has', 'point_count'],
    paint: {
      'text-color': 'white',
    },
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  }

  const unclusteredPointLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'assets-source',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': theme.palette.primary.main,
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': 'white',
    },
  }

  const bounds = useMemo(() => {
    let minLat = -90
    let maxLat = 90
    let minLon = -180
    let maxLon = 180
    if (locations?.length) {
      minLat = locations[0].coordinates.latitude
      maxLat = locations[0].coordinates.latitude
      minLon = locations[0].coordinates.longitude
      maxLon = locations[0].coordinates.longitude
      for (const property of locations) {
        if (property.coordinates.latitude < minLat) {
          minLat = property.coordinates.latitude
        }
        if (property.coordinates.latitude > maxLat) {
          maxLat = property.coordinates.latitude
        }
        if (property.coordinates.longitude < minLon) {
          minLon = property.coordinates.longitude
        }
        if (property.coordinates.longitude > maxLon) {
          maxLon = property.coordinates.longitude
        }
      }
    } else {
      return undefined
    }
    return [minLon, minLat, maxLon, maxLat] as [number, number, number, number]
  }, [locations])

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { padding: 50, screenSpeed: 3 })
    }
  }, [map, bounds])

  return (
    <Source
      id="portfolio-assets"
      type="geojson"
      data={featureCollection as any}
      cluster={true}
      clusterMaxZoom={8}
      clusterRadius={30}>
      <Layer {...clusterLayer} />
      <Layer {...clusterCountLayer} />
      <Layer {...unclusteredPointLayer} />
    </Source>
  )
}

const ActiveThreatLayer = ({ map, setLoading, onClick }: CustomLayerProps) => {
  const groupId = useSelector((state: RootState) => state.system.groupId)
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const locationIds = useMemo(() => locations?.map(location => location.id) ?? [], [locations])
  const { data: allAlerts, isLoading } = usePortfolioAlerts({ only_active: true })
  const alerts = useMemo(
    () => allAlerts?.filter(alert => locationIds.includes(alert.property.id)),
    [allAlerts, locationIds],
  )
  const theme = useTheme()
  if (!alerts) return null

  useEffect(() => {
    setLoading(isLoading)
    return () => {
      setLoading(false)
    }
  }, [isLoading])

  const bounds = useMemo(() => {
    let minLat = -90
    let maxLat = 90
    let minLon = -180
    let maxLon = 180
    if (alerts?.length) {
      minLat = alerts[0].property.coordinates.latitude
      maxLat = alerts[0].property.coordinates.latitude
      minLon = alerts[0].property.coordinates.longitude
      maxLon = alerts[0].property.coordinates.longitude
      for (const alert of alerts) {
        if (alert.property.coordinates.latitude < minLat) {
          minLat = alert.property.coordinates.latitude
        }
        if (alert.property.coordinates.latitude > maxLat) {
          maxLat = alert.property.coordinates.latitude
        }
        if (alert.property.coordinates.longitude < minLon) {
          minLon = alert.property.coordinates.longitude
        }
        if (alert.property.coordinates.longitude > maxLon) {
          maxLon = alert.property.coordinates.longitude
        }
      }
    } else {
      return undefined
    }
    return [minLon, minLat, maxLon, maxLat] as [number, number, number, number]
  }, [alerts])

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { padding: 50, maxZoom: 8, screenSpeed: 3 })
    }
  }, [map, bounds])

  /*
  {
    "event_id": "c06870dc-a83f-4278-9623-2fafaef92e86",
    "type": "flood",
    "type_display": "Flood",
    "severity": 1,
    "created_at": "2023-09-17T13:38:23.777525",
    "updated_at": "2023-09-17T13:38:23.777525",
    "deleted_at": null,
    "title": "Emergecy: flood warning",
    "message": "Test Message",
    "area": null,
    "property": {
        "id": 12543,
        "organisation_id": 1,
        "name": "Tadcaster AFC",
        "property_customer_reference": "f43411c3-0257-4c86-9fb4-f189bf08c617",
        "coordinates": {
            "latitude": 53.8539584,
            "longitude": -1.2054673
        },
        "created_at": "2023-09-16T12:56:59.911515",
        "geometry": null,
        "address": {
            "country": "England",
            "town": "TADCASTER",
            "postcode": "LS24 9SS",
            "street": "INGS ROAD"
        },
        "attributes": [],
        "groups": [],
        "insurance": {
            "value": 3000000,
            "currency_iso": "GBP"
        }
    }
}

  */
  // SeverityIcon
  {
    /* <AlertTypeIcon type={type.name} height={18} width={18} /> {type.display_name} */
  }

  const handleClick = alert => {
    if (!onClick) return
    const node = (
      <Stack width={260}>
        <Stack direction="row" gap={1} flexWrap="wrap">
          <Chip
            avatar={
              <Stack direction="row" alignItems="center" justifyContent="center">
                <SeverityIcon severity={alert.severity} height={20} width={20} />
              </Stack>
            }
            label={DisplaySeverity[alert.severity]}
          />
          <Chip
            avatar={
              <Stack direction="row" alignItems="center" justifyContent="center">
                <AlertTypeIcon type={alert.type} height={20} width={20} />
              </Stack>
            }
            label={alert.type_display}
          />
          {/* <Stack>
              <Box mr={0.5}>
                <SeverityIcon severity={alert.severity} height={20} width={20} />
              </Box>
              <Typography variant="caption">{DisplaySeverity[alert.severity]}</Typography>
            </Stack> */}
          {/* <Stack direction="row" alignItems="center">
            <Box mr={0.5}>
              <AlertTypeIcon type={alert.type} height={20} width={20} />
            </Box>
            <Typography variant="caption">{alert.type_display}</Typography>
          </Stack> */}
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Box maxHeight={200} overflow="auto">
          <Typography variant="body1" gutterBottom>
            <strong>{alert.title}</strong>
          </Typography>
          <Typography variant="body1">{alert.message}</Typography>
        </Box>

        <Typography variant="caption" mt={2}>
          Issued {ago(alert.created_at)}
        </Typography>
        {/* <Divider sx={{ my: 1 }} /> */}

        {/* <Typography variant="body1" fontWeight="bold">
          <Stack direction="row" alignItems="center" gap={0.5}>
            <AlertTypeIcon type={alert.type} height={20} width={20} />
            {alert.type_display}
          </Stack>
        </Typography> */}
      </Stack>
    )
    const coords = {
      lat: alert.property.coordinates.latitude,
      lng: alert.property.coordinates.longitude,
    }
    onClick(alert.property.id, coords, node)
  }

  return alerts?.map(alert => (
    <Marker
      key={alert.id}
      style={{ cursor: 'pointer' }}
      longitude={alert.property.coordinates.longitude}
      latitude={alert.property.coordinates.latitude}
      anchor="center"
      scale={5}
      onClick={() => handleClick(alert)}>
      {/* <img src={floodIcons[alert.severity]} /> */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: 40,
          height: 40,
        }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate(-50%, -50%)',
            '@-webkit-keyframes ping': {
              '0%': {
                '-webkit-transform': 'scale(0.2)',
                transform: 'scale(0.2)',
                opacity: 0.8,
              },
              '80%': {
                '-webkit-transform': 'scale(1.2)',
                transform: 'scale(1.2)',
                opacity: 0,
              },
              '100%': {
                '-webkit-transform': 'scale(2.2)',
                transform: 'scale(2.2)',
                opacity: 0,
              },
            },
            '@keyframes ping': {
              '0%': {
                '-webkit-transform': 'scale(0.2)',
                transform: 'scale(0.2)',
                opacity: 0.8,
              },
              '80%': {
                '-webkit-transform': 'scale(1.2)',
                transform: 'scale(1.2)',
                opacity: 0,
              },
              '100%': {
                '-webkit-transform': 'scale(2.2)',
                transform: 'scale(2.2)',
                opacity: 0,
              },
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: theme.palette.severity[alert.severity],
            outline: '.5px solid grey',
            borderRadius: '50%',
            width: '100%',
            height: '100%',
            animation: 'ping 3s cubic-bezier(0, 0, 0.2, 1) infinite',
            // animationDelay: Math.random() * 2.0 + 's',
          }}
        />
        <Box
          sx={{
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            bgcolor: theme.palette.severity[alert.severity],
            // outline: '.5px solid black',
            boxShadow: '0 2px 0.5px 0px rgba(0,0,0,.3)',
          }}></Box>
      </Box>
    </Marker>
  ))
}

const RiskLayer = ({ map, type, setLoading }: CustomLayerProps & { type: 'river_sea' | 'surface_water' }) => {
  const { data: locations, isLoading } = useLocationsWithRisks()
  const riskType = type

  useEffect(() => {
    setLoading(isLoading)
    return () => {
      setLoading(false)
    }
  }, [isLoading])

  const portfolio = useMemo(() => {
    return locations?.map(property => {
      const risks = property.risks.reduce((acc, risk) => {
        // temporary fix for surface_water
        if (risk.type === 'surface_flood') {
          acc['surface_water'] = risk
        } else {
          acc[risk.type] = risk
        }
        return acc
      }, {})
      return {
        ...property,
        risks,
      }
    })
  }, [locations])

  const bounds = useMemo(() => {
    let minLat = -90
    let maxLat = 90
    let minLon = -180
    let maxLon = 180
    if (locations?.length) {
      minLat = locations[0].coordinates.latitude
      maxLat = locations[0].coordinates.latitude
      minLon = locations[0].coordinates.longitude
      maxLon = locations[0].coordinates.longitude
      for (const property of locations) {
        if (property.coordinates.latitude < minLat) {
          minLat = property.coordinates.latitude
        }
        if (property.coordinates.latitude > maxLat) {
          maxLat = property.coordinates.latitude
        }
        if (property.coordinates.longitude < minLon) {
          minLon = property.coordinates.longitude
        }
        if (property.coordinates.longitude > maxLon) {
          maxLon = property.coordinates.longitude
        }
      }
    } else {
      return undefined
    }
    return [minLon, minLat, maxLon, maxLat] as [number, number, number, number]
  }, [locations])

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, { padding: 50, screenSpeed: 3 })
    }
  }, [map, bounds])

  if (!locations) return null

  const collection = useMemo(() => {
    const features = portfolio?.map(property => {
      return {
        type: 'Feature',
        properties: {
          id: property.id,
          name: property.name,
          value: property.risks[riskType]?.value ?? 0,
          latitude: property.coordinates.latitude,
          longitude: property.coordinates.longitude,
          ...Object.fromEntries(Object.entries(property.risks).map(([key, risk]: [string, any]) => [key, risk.value])),
        },
        geometry: {
          type: 'Point',
          coordinates: [property.coordinates.longitude, property.coordinates.latitude],
        },
      }
    })
    return {
      type: 'FeatureCollection',
      features: features,
    }
  }, [portfolio])

  const colorMapping = {
    river_sea: {
      4: layerColor.riverSea.high,
      3: layerColor.riverSea.medium,
      2: layerColor.riverSea.low,
      1: layerColor.undetermined,
      0: layerColor.undetermined,
    },
    surface_water: {
      4: layerColor.high,
      3: layerColor.medium,
      2: layerColor.low,
      1: layerColor.undetermined,
      0: layerColor.undetermined,
    },
  }

  const layer: LayerProps = {
    id: 'risk-layer',
    type: 'circle',
    source: 'risk-source',
    paint: {
      'circle-radius': 5,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
      'circle-color': [
        'match',
        ['get', riskType],
        4,
        colorMapping[riskType][4],
        3,
        colorMapping[riskType][3],
        2,
        colorMapping[riskType][2],
        1,
        colorMapping[riskType][1],
        0,
        colorMapping[riskType][0],
        'lightgrey',
      ],
    },
  }

  return (
    <>
      <Source id="risk-source" type="geojson" data={collection as any}>
        <Layer {...layer} />
      </Source>
      {/* {portfolio?.map(property => (
        <Marker
          
          key={property.id}
          style={{ cursor: 'pointer' }}
          longitude={property.coordinates.longitude}
          latitude={property.coordinates.latitude}
          anchor="center"
          // onClick={() => openPopup(property)}
        >
          <CircleIcon
            sx={{
              color: riskColor[property.risks[riskType]?.value || 0],
              fontSize: '1.5rem',
            }}
          />
        </Marker>
      ))} */}
    </>
  )
}

export default PortfolioMap
