import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  Card,
  useTheme,
  capitalize,
  Slider,
  InputLabel,
  FormControl,
  Grid,
  Chip,
  Divider,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useClimateAnalyticsWeather,
  useClimateAnalyticsWeatherBreakdown,
  useClimatePropertyData,
  usePropertyClimateAnalyticsDryDays,
  usePropertyClimateAnalyticsHeatStress,
} from '../../../hooks/analytics'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { DataGrid } from '@mui/x-data-grid'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import { FeatureCollection, Point } from '@turf/turf'
import { HazardProps } from '../types'
import { CardTitle } from '../common/Card'
import LoadingIcon from '../../LoadingIcon'
import { useLocations } from '../../../hooks/locations'
import mapboxgl from 'mapbox-gl'
import { GradientLegend } from '../common/Legend'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)
const RiskInfoCard: React.FC<RiskLevelProps & { description: string; date: string }> = ({
  score,
  description,
  date,
}) => {
  const color = waterStressCategoryToColor[score.toString()]

  return (
    <Card variant="outlined" sx={{ borderLeft: `5px solid ${color}`, mb: 2 }}>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="div">
            {description}
          </Typography>
          <Box
            sx={{
              width: 48,
              height: 48,
              border: '1px solid #e0e0e0',
              borderRadius: '50%',
              backgroundColor: color,
            }}
          />
        </Stack>
        <Typography color="text.secondary" variant="body2">
          {date}
        </Typography>
      </Box>
    </Card>
  )
}

const waterStressCategoryToColor = {
  '-1': '#818282',
  '0': '#f8f4a6',
  '1': '#f7e94f',
  '2': '#eb9e3f',
  '3': '#db3e32',
  '4': '#8c2724',
}

type RiskLevelProps = {
  score: -1 | 0 | 1 | 2 | 3 | 4
}

const RiskLevel: React.FC<RiskLevelProps> = ({ score }) => {
  const color = waterStressCategoryToColor[score.toString()]

  return (
    <Box
      sx={{
        width: 48,
        height: 48,
        border: '1px solid #e0e0e0',
        borderRadius: '50%',
        backgroundColor: color,
      }}
    />
  )
}

export const PropertyWaterRisk: React.FC<HazardProps> = ({ selectedScenario, setScenarios }) => {
  const { id: propertyId } = useParams()
  const { data } = useClimatePropertyData(propertyId, 'water-risk')

  console.log(data)
  console.log('wawa')

  const availableScenarios = useMemo(() => {
    return Object.keys(data?.indicators ?? [])
  }, [data])

  // Set hazard's scenarios
  useEffect(() => {
    setScenarios(availableScenarios)
    return () => {
      setScenarios([])
    }
  }, [availableScenarios])

  console.log('selectedScenario', selectedScenario)

  const scenarioData = data?.indicators?.[selectedScenario]?.wr?.data?.data

  if (!data || !scenarioData) {
    return <Typography>No data available for this scenario</Typography>
  }

  console.log('scenarioData', scenarioData)

  return (
    <Stack direction="column" spacing={5}>
      {/* Overview / Water Stress */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Overview</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RiskInfoCard
              score={scenarioData[0].indicators.ws.category}
              description={scenarioData[0].indicators.ws.description}
              date={scenarioData[0].year}
            />
          </Grid>
          <Grid item xs={6}>
            <RiskInfoCard
              score={scenarioData[1].indicators.ws.category}
              description={scenarioData[1].indicators.ws.description}
              date={scenarioData[1].year}
            />
          </Grid>
        </Grid>
      </Stack>

      {/* Breakdown /  All Indicators */}
      <Stack direction="column">
        <Typography variant="overline">{/* <strong>Water Stres</strong> */}</Typography>
      </Stack>
    </Stack>
  )
}

export default PropertyWaterRisk
