import {
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Menu,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useClimateMitigations, useClimatePropertyData } from '../../../hooks/analytics'
import { useMemo, useState } from 'react'
import { EditNotifications, HelpRounded, InfoOutlined } from '@mui/icons-material'
import {
  scoreColor as floodScoreColor,
  getRating as getFloodRating,
} from '../../climateAnalytics/portfolio/flood/utils'
import {
  scoreColor as windScoreColor,
  beaufortToCategory,
  categoryToLabel,
} from '../../climateAnalytics/portfolio/extremeWind/utils'
import {
  scoreToColor as heatScoreToColor,
  scoreToCategory as heatScoreToCategory,
} from '../../climateAnalytics/portfolio/heatStress/utils'

function getFloodScore(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in flood data`)
    return null
  }
  console.log(data)
  return data.scores?.[termIdx] ?? null
}

function getWindScore(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.wg?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in wind gust data`)
    return null
  }
  return data?.wg?.main_data?.wg?.[termIdx] ?? null
}

function getHeatScore(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.hshd?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in wind gust data`)
    return null
  }
  return data?.hshd?.main_data?.['hot_days']?.[termIdx] ?? null
}

function getDryDays(data: any, term: 'short' | 'mid' | 'long'): number | null {
  if (!data) {
    return null
  }
  const termIdx = data?.dd?.metadata?.[`${term}_term_idx`] ?? null
  if (termIdx == null) {
    console.error(`No ${term} term index found in drought data`)
    return null
  }
  return data?.dd?.main_data?.dd?.[termIdx] ?? null
}

function getWaterStress(data: any, term: 'short' | 'mid' | 'long'): any | null {
  if (!data) {
    return null
  }
  const termYearMap = {
    short: '2030',
    mid: '2050',
    long: '2080',
  }
  const termYear = termYearMap[term]
  for (const item of data) {
    if (item.year === termYear) {
      return item.indicators.ws
    }
  }
  console.error(`No data found for term ${term}`)
  return null
}

const FloodScore = ({ value }: { value: number | null }) => {
  const color = value != null ? floodScoreColor?.[value] ?? null : null
  const normalValue = ((value ?? 0) / 5) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const WindScore = ({ value }: { value: number | null }) => {
  const color = value != null ? windScoreColor?.[value] ?? null : null
  const normalValue = ((value ?? 0) / 5) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const HeatScore = ({ value }: { value: number | null }) => {
  // const color = value != null ? heatScoreToColor(value) : null
  const color = '#f3b59c'
  const normalValue = Math.min(((value ?? 0) / 90) * 100, 100)
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const DryDays = ({ value }: { value: number | null }) => {
  const color = '#d1b8a7'
  const normalValue = ((value ?? 0) / 365) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const WaterRisk = ({ value }: { value: number | null }) => {
  const color = '#d1b8a7'
  const normalValue = ((value ?? 0) / 5) * 100
  return <ScoreLinearProgress value={normalValue} color={color} />
}

const ScoreLinearProgress = ({ value, color }: { value: number; color: string | null }) => {
  if (color == null) {
    color = 'lightgrey'
  }
  return (
    <>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: '4px',
          borderRadius: 5,
          backgroundColor: '#f0f0f0',
          '& .MuiLinearProgress-bar': { backgroundColor: color },
        }}
      />
    </>
  )
}

const hazardTooltip = {
  flood:
    'Flood score reflects the comprehensive flood risk from coastal, river, and surface water sources, using high-resolution data and projections to assess future flood hazards under various scenarios and periods.',
  wind: 'Extreme wind score reflects the intensity of wind hazards by averaging the top 30th day of daily maximum wind gusts for each year over a 20-year period, and then classifying the result according to the Beaufort scale.',
  'heat-stress':
    'Heat score is calculated by averaging the maximum daily heat index values during the hottest month each year over a 20-year period, which incorporates temperature and humidity to assess perceived heat.',
  drought:
    'Dry days show the number of days per year with less than 1mm of precipitation, highlighting the frequency of dry conditions.',
  'water-risk':
    'Water stress reflects the level of water scarcity and risk of water shortages, based on the Water Risk Atlas data and projections for the future.',
}

const rcpToSsp = {
  rcp26: 'ssp126',
  rcp45: 'ssp245',
  rcp85: 'ssp585',
}

const PropertyClimateHazardsCard = ({ propertyId }) => {
  const [emissions, setEmissions] = useState('rcp85')
  const [term, setTerm] = useState<'long' | 'mid' | 'short'>('long')

  const { data: mitigationCategories } = useClimateMitigations(propertyId)

  const countsByCategory: Record<string, number> = useMemo(() => {
    const counts: Record<string, number> = {}
    if (mitigationCategories && Array.isArray(mitigationCategories)) {
      for (const category of mitigationCategories) {
        for (const mitigation of category?.mitigations ?? []) {
          if (mitigation?.added) {
            counts[category.name] = (counts[category.name] ?? 0) + 1
          }
        }
      }
    }
    return counts
  }, [mitigationCategories])

  const mitigationCount: number = Object.values(countsByCategory).reduce((acc: number, count: number) => acc + count, 0)

  const hazardConfigs = [
    {
      key: 'flood',
      label: 'Flood',
      dataKey: 'flood-scores',
      tooltip: hazardTooltip['flood'],
      countsKey: 'flood',
      useDataHook: () => useClimatePropertyData(propertyId, 'flood-scores'),
      getScore: data => {
        const score = getFloodScore(data?.[emissions]?.[propertyId], term)
        const scoreText = score ? `${getFloodRating(score)} (${score})` : 'N/A'
        return { score, scoreText }
      },
      ScoreComponent: FloodScore,
    },
    {
      key: 'wind',
      label: 'Extreme Wind',
      dataKey: 'wind',
      tooltip: hazardTooltip['wind'],
      countsKey: 'wind',
      useDataHook: () => useClimatePropertyData(propertyId, 'wind'),
      getScore: data => {
        const score = getWindScore(data?.indicators?.[emissions], term)
        const scoreText = score ? `${categoryToLabel(score)} (${score})` : 'N/A'
        return { score, scoreText }
      },
      ScoreComponent: WindScore,
    },
    {
      key: 'heat-stress',
      label: 'Heat Stress',
      dataKey: 'heat-stress',
      tooltip: hazardTooltip['heat-stress'],
      countsKey: 'heat-stress',
      useDataHook: () => useClimatePropertyData(propertyId, 'heat-stress'),
      getScore: data => {
        const score = getHeatScore(data?.indicators?.[emissions], term)
        const scoreText = score ? `${score.toFixed(0)} local hot days/year` : 'N/A'
        return { score, scoreText }
      },
      ScoreComponent: HeatScore,
    },
    {
      key: 'drought',
      label: 'Drought',
      dataKey: 'drought',
      tooltip: hazardTooltip['drought'],
      countsKey: 'drought',
      useDataHook: () => useClimatePropertyData(propertyId, 'drought'),
      getScore: data => {
        const score = getDryDays(data?.indicators?.[emissions], term)
        const scoreText = score ? `${score.toFixed(0)} dry days/year` : 'N/A'
        return { score, scoreText }
      },
      ScoreComponent: DryDays,
    },
    {
      key: 'water-risk',
      label: 'Water Stress',
      dataKey: 'water-risk',
      tooltip: hazardTooltip['water-risk'],
      countsKey: 'water-risk',
      useDataHook: () => useClimatePropertyData(propertyId, 'water-risk'),
      getScore: data => {
        const riskData = getWaterStress(data?.indicators?.[rcpToSsp[emissions]]?.wr.data.data, term)
        const score = riskData?.score?.toFixed(1)
        const scoreText = riskData?.description ?? 'N/A'
        return { score, scoreText }
      },
      ScoreComponent: WaterRisk,
    },
  ]
  const hazardData = hazardConfigs.map(hazard => {
    const { data, isLoading } = hazard.useDataHook()
    const { score, scoreText } = hazard.getScore(data)
    return { ...hazard, data, isLoading, score, scoreText }
  })

  const allScoresNull = hazardData.every(hazard => hazard.score == null)
  const allLoaded = hazardData.every(hazard => !hazard.isLoading)

  return (
    <Card>
      <CardHeader
        title={
          <>
            Climate Risk Exposure{' '}
            {mitigationCount > 0 ? (
              <Chip size="small" variant="outlined" label={`${mitigationCount} mitigation(s)`} color="info" />
            ) : null}
          </>
        }
      />
      <CardContent
        sx={{
          paddingX: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
        }}>
        {!allLoaded && (
          <Stack>
            <Skeleton variant="rounded" height={24} sx={{ m: 0, p: 0 }} />
          </Stack>
        )}
        <Stack minWidth={200} spacing={2}>
          {allScoresNull && allLoaded ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                height: 25,
              }}>
              <Typography variant="body1" color="text.secondary">
                No hazards available
              </Typography>
            </Stack>
          ) : (
            <List>
              {hazardData
                .filter(hazard => hazard.score != null)
                .map((hazard, index, array) => (
                  <div key={hazard.key}>
                    <ListItem>
                      <Stack flexGrow={1} direction="row" justifyContent="space-between" alignItems="center">
                        <Tooltip title={hazard.tooltip} arrow>
                          <Stack direction="row" spacing={0.5}>
                            <Badge
                              color="info"
                              variant="standard"
                              badgeContent={countsByCategory[hazard.countsKey] ?? 0}>
                              <Typography>{hazard.label}</Typography>
                            </Badge>
                            <InfoOutlined sx={{ width: 16 }} color="disabled" />
                          </Stack>
                        </Tooltip>
                        <Box
                          sx={{
                            width: 200,
                          }}>
                          <>
                            <Typography variant="body1">{hazard.scoreText}</Typography>
                            <hazard.ScoreComponent value={hazard.score} />
                          </>
                        </Box>
                      </Stack>
                    </ListItem>
                    {index < array.length - 1 && <Divider sx={{ my: 1 }} />}
                  </div>
                ))}
            </List>
          )}

          <Stack direction="row" alignItems="center" rowGap={2} columnGap={2} flexWrap="wrap">
            <Box>
              <Typography variant="subtitle2">Climate Scenario</Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  label="Optimistic"
                  onClick={() => setEmissions('rcp26')}
                  color={emissions === 'rcp26' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Moderate"
                  onClick={() => setEmissions('rcp45')}
                  color={emissions === 'rcp45' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Pessimistic"
                  onClick={() => setEmissions('rcp85')}
                  color={emissions === 'rcp85' ? 'primary' : 'default'}
                />
              </Stack>
            </Box>

            <Box>
              <Typography variant="subtitle2">Time Horizon</Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  label="Near"
                  onClick={() => setTerm('short')}
                  color={term === 'short' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Mid"
                  onClick={() => setTerm('mid')}
                  color={term === 'mid' ? 'primary' : 'default'}
                />
                <Chip
                  size="small"
                  label="Long"
                  onClick={() => setTerm('long')}
                  color={term === 'long' ? 'primary' : 'default'}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}

export default PropertyClimateHazardsCard
