import { useEffect, useMemo, useRef } from 'react'
import { useClimatePortfolioData } from '../../hooks/analytics'
import { Box, Card, FormControl, InputLabel, MenuItem, Select, Stack, Typography, useTheme } from '@mui/material'
import { CardTitle } from './common/Card'
import { useLocations } from '../../hooks/locations'
import LoadingIcon from '../LoadingIcon'
import PortfolioFloodBreakdown from './portfolio/flood/PortfolioFloodBreakdown'
import PortfolioFloodMap from './portfolio/flood/PortfolioFloodMap'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

const PortfolioFlood = ({ selectedScenario, setScenarios }) => {
  const { groupId } = useSelector((state: RootState) => state.system)
  const { data: locations, isLoading: isLocationsLoading } = useLocations({ group_id: groupId })
  const { data, isLoading } = useClimatePortfolioData('flood-scores')
  const theme = useTheme()

  const availableScenarios = useMemo(() => {
    return Object.keys(data ?? {})
  }, [data])

  useEffect(() => {
    setScenarios(availableScenarios)
  }, [availableScenarios])

  const locationById = useMemo(() => {
    return locations.reduce((acc, location) => {
      acc[location.id] = location
      return acc
    }, {})
  }, [locations])

  const dataById = useMemo(() => {
    return data?.[selectedScenario] ?? null
  }, [data, selectedScenario])

  if (isLoading || isLocationsLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="200px">
        <LoadingIcon />
      </Stack>
    )
  }

  const gridSpacing = '16px'
  return (
    <Stack alignItems={'center'} pb={6}>
      <Stack sx={{ maxWidth: theme.breakpoints.values?.['lg'], width: '100%' }} direction="column">
        <Box
          sx={{
            display: 'grid',
            gridGap: gridSpacing,
            gridTemplateRows: 'repeat(10, 180px)',
            gridTemplateColumns: 'repeat(8, 1fr)',
            gridTemplateAreas: `"breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
           "map map map map map map map map"
           "map map map map map map map map"
           "map map map map map map map map"
           `,
            //   gridTemplateAreas: `"chart chart chart chart card1 card1 card2 card2"
            //  "chart chart chart chart card3 card3 card4 card4"
            //  "comp comp comp comp comp dist dist dist"
            //  "comp comp comp comp comp dist dist dist"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "breakdown breakdown breakdown breakdown breakdown breakdown breakdown breakdown"
            //  "map map map map map map map map"
            //  "map map map map map map map map"
            //  "map map map map map map map map"
            //  `,
          }}>
          {/* --------- */}
          {/* Breakdown */}
          {/* --------- */}
          <PortfolioFloodBreakdown scenario={selectedScenario} />

          {/* --- */}
          {/* Map */}
          {/* --- */}
          <Card sx={{ gridArea: 'map', display: 'flex', flexDirection: 'column' }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <CardTitle title="Map" subtitle="Analyse geospatial distribution of risk exposure" />
            </Stack>
            <PortfolioFloodMap scenario={selectedScenario} />
          </Card>
        </Box>
      </Stack>
    </Stack>
  )
}

export default PortfolioFlood
